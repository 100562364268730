#primary-newsletter-cta {
  [data-sr-convertkit-subscribe-form] {
    @apply flex w-full flex-col;
    [data-sr-input] {
      @apply mb-8 w-full rounded-full border-none bg-foreground px-8 py-6 text-xl text-background;
    }
    [data-sr-input-label] {
      @apply inline-block px-8 pb-4;
    }
    [data-sr-input-asterisk] {
    }
    [data-sr-button] {
      @apply w-full rounded-full bg-background px-8 py-10 text-xl text-foreground focus-visible:outline-2 focus-visible:outline-white;
    }
  }
}

#video-overlay {
  [data-sr-convertkit-subscribe-form] {
    @apply mx-auto flex w-full flex-col;
    [data-sr-input-wrapper] {
      @apply text-left;
    }
    [data-sr-input] {
      @apply mb-4 block w-full rounded-lg border px-4 py-3 text-black placeholder-opacity-60 shadow sm:text-base sm:leading-6;
    }
    [data-sr-input-label] {
      @apply inline-block pb-1 text-sm;
    }
    [data-sr-input-asterisk] {
      @apply opacity-50;
    }
    [data-sr-button] {
      @apply relative mt-2 flex items-center justify-center rounded-md bg-primary py-3 font-bold text-primary-foreground transition hover:brightness-110 focus-visible:ring-ring;
    }
  }
}
