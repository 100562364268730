@tailwind base;
@tailwind components;
@tailwind utilities;

@import './quiz.css';
@import './primary-newsletter-cta.css';
@import './tips.css';
@import './nprogress.css';
@import './video/mux-player.css';
@import './video/github-link.css';
@import './video/lesson-completion-toggle.css';
@import './video/lesson-description.css';
@import './video/lesson-title.css';
@import './video/module-lesson-list.css';
@import './video/video-overlays.css';
@import './video/video-transcript.css';
@import './video/module-navigator.css';
@import './focus-visible.css';
@import '@code-hike/mdx/dist/index.css';
@import './ui/button.css';
@import './login.css';
@import './commerce.css';
@import 'https://fast.fonts.net/t/1.css?apiType=css&projectid=17609524-d858-4b4d-8437-230f1df1ac6c';

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 0%;

    --muted: 223 47% 11%;
    --muted-foreground: 0 0% 50%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 0 0% 94%;
    --input: 0 0% 94%;

    --primary: 148 82% 85%;
    --primary-foreground: 0 0% 0%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 148 82% 85%;

    --radius: 0.33rem;
  }

  .dark {
    --background: 0 0% 0%;
    --foreground: 0 0% 100%;

    --muted: 223 47% 11%;
    --muted-foreground: 0 0% 50%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 0 0% 11%;
    --input: 0 0% 15%;

    /* mint green */
    --mint: 148 82% 85%;
    /* michelle green */
    --primary: 146 56% 71%;
    --primary-foreground: 0 0% 0%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 148 82% 85%;

    --radius: 0.33rem;
    color-scheme: dark;
  }
}

@layer base {
  * {
    @apply border-border selection:bg-primary selection:text-primary-foreground;
  }
  body {
    @apply bg-background text-foreground antialiased;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}
