.button-primary-border {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.16) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    hsl(var(--primary));
  box-shadow: 0px 1px 2px rgba(17, 20, 79, 0.4),
    0px 0px 0px 1px rgba(1, 92, 228, 0.76);
  background-clip: padding-box; /* !importanté */
  border: 1.5px solid transparent; /* !importanté */
  text-shadow: 0px 1px 2px rgba(17, 20, 79, 0.2);
}
.button-primary-border:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1.5px; /* !importanté */
  border-radius: inherit; /* !importanté */
  background: linear-gradient(to bottom, #809dff, hsl(var(--primary)));
}
