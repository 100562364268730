mux-player {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
}

mux-player::part(center play button) {
  @apply sm:scale-50;
}

mux-player::part(vertical-layer) {
  background-color: rgba(0, 0, 0, 0);
}
